import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: "40rem",
    position: "relative",
    overflowY: "scroll",
    maxHeight: "30rem",
    borderRadius: "unset",
  },
  heading: {
    padding: 10,
    minheight: 50,
    backgroundColor: "#4D4D4D",
    color: "#FFFFFF",
    textAlign: "left",
    fontSize: "20px",
  },
  question: {
    padding: "10px",
  },
  definition: {
    padding: "10px",
    fontSize: "19px",
    textAlign: "left",
  },
  ivdLink: {
    color: "#DC1A22",
    textDecoration: "none",
  },
}));

const DefinitionCard = (props) => {
  const disclaimer = props.showDisclaimer;
  const classes = useStyles();
  return (
    <div>
      <Paper className={classes.paper}>
        <div className={classes.heading}>
          <p>Begriffsdefinitionen</p>
        </div>
        <div>
          {disclaimer ? (
            <div className={classes.definition}>
              <p>
                Die mit diesem Programm erzeugte Klassifizierung dient lediglich
                als Hinweis und bildet keine rechtssichere Aussage.Im
                Zweifelsfall sollten Sie sich an Ihre zuständigen Behörde
                wenden. Natürlich dürfen Sie sich jederzeit auch an uns - das
                Johner Institut - wenden! Wir helfen Ihnen gerne weiter.
              </p>
              <p>Noch nicht zufrieden mit der Klassifizierung ?</p>
              <a
                className={classes.ivdLink}
                href="https://www.johner-institut.de/ivd-zulassungsstrategie/"
                target="_blank"
              >
                Ivd-Zulassungsstrategie
              </a>
            </div>
          ) : (
            <div className={classes.definition}>
              {props.definition.map((item) => (
                <div>
                  <p>
                    <strong>{item.begriff}</strong>
                  </p>
                  <p>{item.erklärung}</p>
                  {item.hyperlink !== "" ? (
                    <a href={item.hyperlink} className={classes.ivdLink}>
                      {item.hyperlink}
                    </a>
                  ) : (
                    <div></div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default DefinitionCard;
