import React from "react";
import { useState, useEffect } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import data from "../data/Start.json";
import { makeStyles, withTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  heading: { textAlign: "left" },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paper: {
    padding: "20px",
  },
  cardText: {
    color: "black",
  },
  paperWidth: {
    width: "300px",
    margin: "auto",
  },
  startingButton: {
    width: "100%",
  },
  stepIcon: {
    color: "#DC1A22 !important",
    opacity: 0.8,
  },
}));

const StartingCard = (props) => {
  const classes = useStyles();

  const startingQuestions = data;
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [trigger, setTrigger] = useState();
  const [spefificQuestion, setSpecificQuestion] = useState(false);
  const [open, setOpen] = useState(false);
  const [startClassification, setClassification] = useState(false);

  const handleClickOpen = (id, index) => {
    if (id === "4") {
      setSpecificQuestion(true);
      setActiveStep(index);
      setOpen(false);
    } else {
      setSpecificQuestion(false);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = (index) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === steps.length - 1) {
      setClassification(true);
      props.start(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getSteps() {
    let steps = [];
    startingQuestions.map((item) => {
      steps.push(item.title);
    });
    return steps;
  }

  function getStepContent(step) {
    return startingQuestions[step].Frage;
  }

  const renderAnswer = (index) => {
    return (
      <div>
        {!spefificQuestion && open ? (
          <div>
            {trigger ? (
              <p className={classes.cardText}>{startingQuestions[index].ja}</p>
            ) : (
              <p className={classes.cardText}>
                {startingQuestions[index].nein}
              </p>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const renderButtons = (index) => {
    return (
      <div>
        {startingQuestions[index].ja !== "" ? (
          <div>
            <Button
              color="primary"
              onClick={() => {
                setTrigger(true);
                handleClickOpen(
                  startingQuestions[index].id,
                  startingQuestions[index].ja
                );
              }}
            >
              JA
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setTrigger(false);
                handleClickOpen(
                  startingQuestions[index].id,
                  startingQuestions[index].nein
                );
              }}
            >
              Nein
            </Button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className={classes.root}>
        <h2 className="heading">Eingangsfragen</h2>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{ classes: { root: classes.stepIcon } }}
              >
                {label}
              </StepLabel>
              <StepContent>
                <Paper className={classes.paper}>
                  {!open ? (
                    <div>
                      <p className={classes.cardText}>
                        {getStepContent(index)}
                      </p>
                      {renderButtons(index)}
                    </div>
                  ) : (
                    renderAnswer(index)
                  )}
                </Paper>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={() => {
                        handleBack();
                        handleClose();
                      }}
                      className={classes.button}
                    >
                      Zurück
                    </Button>

                    <button
                      className="btn btn-red"
                      onClick={() => {
                        setOpen(false);
                        handleNext(index);
                      }}
                    >
                      {activeStep === steps.length - 1 ? "Start" : "Weiter"}
                    </button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  );
};

export default StartingCard;
