import React from "react";
import "./App.css";
import IvdrCalculator from "./components/IvdrCalculator";
import StartingCard from "./components/StartingCard";
import { useState } from "react";
import Container from "@material-ui/core/Container";

const App = () => {
  const [start, setStart] = useState(false);

  return (
    <div>
      <div className="App">
        <Container fixed>
          {start ? (
            <IvdrCalculator start={(e) => setStart(e)} />
          ) : (
            <StartingCard start={(e) => setStart(e)} />
          )}
        </Container>
      </div>
    </div>
  );
};

export default App;
