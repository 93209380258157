import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { conformityText } from "./helper";
import Grid from "@material-ui/core/Grid";
import QuestionCard from "./QuestionCard";
import DefinitionCard from "./DefinitionCard";
import { useState, useEffect } from "react";
import data from "../data/Rules.json";
import AnswerCard from "./AnswerCard";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  backBtn: {
    textAlign: "left",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    color: "DC1A22",
  },
  link: { color: "#DC1A22" },
}));

const IvdrCalculator = (props) => {
  const classes = useStyles();
  const questions = data;
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [lastStep, setLastStep] = useState(false);
  const [trigger, setTrigger] = useState({
    trigger: false,
    button: true,
    restart: false,
  });

  const [additionalText, setAdditionalText] = useState();
  const defaultClass = "Klasse B";
  const ivdrClasses = [
    "Klasse D",
    "Klasse C",
    "Klasse B",
    "Klasse As",
    "Klasse A",
  ];
  const conformityLevel = ["4", "3", "2", "1s", "1"];
  const endId = "Ende";

  const [currentObject, setObject] = useState({
    id: "1",
    Frage: questions[0].Frage,
    nextid: questions[0].nextid,
    Klasse: questions[0].ja.Klasse,
    Konformitätsverfahren: questions[0].ja.Konformitätsverfahren,
    Begriffsdefinitionen: questions[0].Begriffsdefinition,
  });
  const [answers, setAnswers] = useState([]);
  const [result, setResult] = useState({
    Klasse: "",
    Konformitätsverfahren: "",
  });

  let findQuestion = (id) => {
    if (currentObject.nextid === "Ende" && !lastStep) {
      finalResult(answers);
      setShowDisclaimer(true);
    } else {
      filter(id);
    }
  };

  function filter(id) {
    let filteredQuestion = questions.find((element) => element.id === id);
    setObject({
      id: filteredQuestion.id,
      Frage: filteredQuestion.Frage,
      nextid: filteredQuestion.nextid,
      Klasse: filteredQuestion.ja.Klasse,
      Konformitätsverfahren: filteredQuestion.ja.Konformitätsverfahren,
      Begriffsdefinitionen: filteredQuestion.Begriffsdefinition,
    });
  }

  let resultClass = (arr) => {
    let x = arr.filter((item) => item.button === "ja");
    let ivdrClass = defaultClass;
    for (const item in ivdrClasses) {
      if (x.some((a) => a.Klasse === ivdrClasses[item])) {
        ivdrClass = ivdrClasses[item];
        break;
      }
    }
    return ivdrClass;
  };

  let resultConformity = (arr) => {
    let x = arr.filter((item) => item.button === "ja");
    let ivdrConformity = "2";
    for (const item in conformityLevel) {
      if (x.some((a) => a.Konformitätsverfahren === conformityLevel[item])) {
        ivdrConformity = conformityLevel[item];
        break;
      }
    }
    return ivdrConformity;
  };

  function finalResult(x) {
    let finalClass = resultClass(x);
    let finalConformity = resultConformity(x);
    let conformity = conformityText(finalConformity);
    setResult({
      Klasse: finalClass,
      Konformitätsverfahren: conformity,
    });
  }

  let reset = () => {
    setObject({
      id: "1",
      Frage: questions[0].Frage,
      nextid: questions[0].nextid,
      Klasse: questions[0].ja.Klasse,
      Konformitätsverfahren: questions[0].ja.Konformitätsverfahren,
      Begriffsdefinitionen: questions[0].Begriffsdefinition,
    });
    setTrigger({
      trigger: false,
      button: false,
      restart: false,
    });
    setAnswers([]);
    setAdditionalText();
    setShowDisclaimer(false);
  };

  let updateAnswers = (value) => {
    currentObject.button = value;
    setAnswers([...answers, currentObject]);
    if (value === "ja") {
      checkSpecificId(currentObject.id);
    }
  };

  let previousQuestion = () => {
    if (currentObject.id != 1) {
      setShowDisclaimer(false);
      let copy = [...answers];
      let lastElementId = answers[answers.length - 1].id;
      findQuestion(lastElementId);
      copy.pop();
      setAnswers(copy);
    }
    if (currentObject.nextid === endId) {
      setLastStep(true);
    }
  };

  let findAnsweredQuestion = (id) => {
    setShowDisclaimer(false);
    setAdditionalText();
    let copy = [...answers];
    let index = answers.findIndex((item) => item.id === id);
    copy.splice(index, answers.length);
    setAnswers(copy);
    filter(id);
  };

  let checkSpecificId = (id) => {
    if (id === "26") {
      setAdditionalText(
        "Ihr Produkt ist ein patientennaher Test (POCT), daher ist die Prüfung der technischen Dokumentation für jedes Produkt erforderlich und die Benannte Stelle stellt eine entsprechende EU-Bescheinigung aus."
      );
    } else if (id === "20" || id === "21" || id === "22") {
      setAdditionalText(
        "Ihr Produkt ist ein Produkt zur Eigenanwendung, daher ist die Prüfung der technischen Dokumentation für jedes Produkt erforderlich und die Benannte Stelle stellt eine entsprechende EU-Bescheinigung aus."
      );
    } else if (id === "11") {
      setAdditionalText(
        "Ihr Produkt ist ein therapiebegleitendes Diagnostikum (CDx) daher ist die Prüfung der technischen Dokumentation für jedes Produkt erforderlich und die Benannte Stelle stellt eine entsprechende EU-Bescheinigung aus."
      );
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.backBtn}>
        <Link
          className={classes.link}
          component="button"
          variant="body2"
          onClick={() => {
            props.start(false);
          }}
        >
          Zurück zu den Eingangsfragen
        </Link>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <QuestionCard
            question={currentObject}
            find={(e) => findQuestion(e)}
            reset={() => reset()}
            updateAnswers={updateAnswers}
            previousQuestion={previousQuestion}
            disclaimer={setShowDisclaimer}
            showDisclaimer={showDisclaimer}
            result={result}
            answers={answers}
            lastStep={setLastStep}
            additionalText={additionalText}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DefinitionCard
            showDisclaimer={showDisclaimer}
            definition={currentObject.Begriffsdefinitionen}
          />
        </Grid>
        <Grid item xs={12}>
          <AnswerCard
            answers={answers}
            question={(e) => findAnsweredQuestion(e)}
            disclaimer={setShowDisclaimer}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default IvdrCalculator;
