/**
 * /**
 * Function to use markdown in the specified json file
 * @param text
 * @returns {*}
 */
export function markdownDetection(text) {
  const htmlText = text
    .replace(/\#\#\#(.*)\#\#\#/gim, "<h3>$1</h3>")
    .replace(/\#\#(.*)\#\#/gim, "<h2>$1</h2>")
    .replace(/\#(.*)\#/gim, "<h1>$1</h1>")
    .replace(/^\> (.*$)/gim, "<blockquote>$1</blockquote>")
    .replace(/\*\*(.*)\*\*/gim, "<strong>$1</strong>")
    .replace(/\*\*\*(.*)\*\*\*/gim, "<strong><em>$1</em></strong>")
    .replace(/\*(.*)\*/gim, "<i>$1</i>")
    .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
    .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
    .replace(/\n/g, "<br/>");
  return htmlText.trim();
}

/**
 * Function checks conformity level and returns associated text
 * @param {*} level
 * @returns
 */
export let conformityText = (level) => {
  switch (level) {
    case "4":
      return "Konformitätsbewertungsverfahren gemäß Anhängen IX bis XI. Audit des Qualitätsmanagements-Systems und Prüfung der technischen Dokumentation, sowie Chargenfreigabe durch Referenzlaboratorien.";
    case "3":
      return "Ihr Produkt fällt unter die Klasse C. Es ist ein  Konformitätsbewertungsverfahren gemäß Anhängen IX bis XI erforderlich. Dieses beinhaltet ein Audit des Qualitätsmanagements-Systems und Prüfung der technischen Dokumentation, gegebenfalls für repräsentative Produkte einer Produktgruppe.";
    case "2":
      return "Konformitätsbewertungsverfahren gemäß Anhängen IX bis XI. Audit des Qualitätsmanagements-Systems und Prüfung der technischen Dokumentation, gegebenfalls für repräsentative Produkte einer Produktkategorie.";
    case "1s":
      return "Konformitätsbewertungsverfahren gemäß Anhängen IX bis XI. Die Beteiligung der Benannten Stelle beschränkt sich auf die Sterilisationsaspekte.";
    case "1":
      return "Konformitätserklärung gemäß Anhang IV und V ohne Beteiligung einer Benannten Stelle.";
  }
};
