import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { markdownDetection } from "./helper";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: "40rem",
    position: "relative",
    borderRadius: "unset",
  },
  heading: {
    padding: 10,
    minHeight: "50px",
    backgroundColor: "#4D4D4D",
    color: "#FFFFFF",
    textAlign: "left",
    fontSize: "20px",
  },
  question: {
    padding: "10px",
    fontSize: "19px",
    textAlign: "left",
  },
}));

const QuestionCard = (props) => {
  const classes = useStyles();
  const question = props.question;
  const result = props.result;

  return (
    <div>
      <Paper className={classes.paper}>
        <div className={classes.heading}>
          {props.showDisclaimer === false ? (
            <p>Trifft diese Beschreibung auf ihr Produkt zu ?</p>
          ) : (
            <p>Klassifizierung abgeschlossen</p>
          )}
        </div>
        <div className={classes.question}>
          {props.showDisclaimer === false ? (
            <p>{parse(markdownDetection(question.Frage))}</p>
          ) : (
            <div>
              <p>
                Ihr IVD gehört zur : <strong>{result.Klasse} </strong>
              </p>
              <hr />
              <p>
                Um dieses Produkt CE Konform zu zertifizieren benötigen sie
                dieses Konformitätsbewertungsverfahren:
              </p>
              <p>
                <strong>{result.Konformitätsverfahren}</strong>
              </p>
              <p>{props.additionalText}</p>
            </div>
          )}
        </div>
        <div className="button_box">
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <Button className="btn restart-btn" onClick={() => props.reset()}>
                NEUSTART
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                className="btn restart-btn"
                onClick={() => {
                  props.previousQuestion();
                }}
              >
                Zurück
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              {props.showDisclaimer !== true ? (
                <button
                  className="btn btn-red"
                  onClick={() => {
                    props.updateAnswers("ja");
                    props.find(question.nextid);
                    props.lastStep(false);
                  }}
                >
                  JA
                </button>
              ) : (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  disabled
                >
                  JA
                </Button>
              )}
            </Grid>
            <Grid item xs={6} sm={3}>
              {props.showDisclaimer !== true ? (
                <button
                  className="btn btn-red"
                  onClick={() => {
                    props.updateAnswers("nein");
                    props.find(question.nextid);
                    props.lastStep(false);
                  }}
                >
                  Nein
                </button>
              ) : (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  disabled
                >
                  Nein
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
};

export default QuestionCard;
