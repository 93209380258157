import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: 500,
    position: "relative",
  },
  heading: {
    padding: 10,
    height: 50,
    backgroundColor: "#4D4D4D",
    color: "#FFFFFF",
    textAlign: "left",
    fontSize: "20px",
  },
  question: {
    padding: "10px",
    fontSize: "19px",
    textAlign: "left",
  },
  buttonBox: {
    position: "absolute",
    bottom: "20px",
    padding: "10px",
    width: "100%",
  },
  tableHeader: {
    backgroundColor: "#4D4D4D",
  },
  button: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    height: "400px",
    borderRadius: "unset",
  },
  tableRow: {
    cursor: "pointer",
  },
  tableCell: {
    color: "white",
  },
  link: { color: "#DC1A22" },
}));

const AnswerCard = (props) => {
  const classes = useStyles();
  const answers = props.answers;

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell className={classes.tableCell}>ID</TableCell>
            <TableCell className={classes.tableCell}>Frage</TableCell>
            <TableCell className={classes.tableCell}>Beantwortet mit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {answers.map((row) => (
            <TableRow
              key={row.id}
              className={classes.tableRow}
              onClick={() => {
                props.question(row.id);
                props.disclaimer(false);
              }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell>
                {row.Frage}
                <div>
                  <Link className={classes.link}>
                    Zu dieser Frage zurückspringen ?
                  </Link>
                </div>
              </TableCell>
              <TableCell>{row.button}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AnswerCard;
